import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { H1, H3, P } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/ecommerceStoreVp1.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import BigPurpleCard from '@/components/BigPurpleCard';
import ReversibleCardsComponent from '@/components/ReversibleCardsComponent';
import { B2BEcommerceStorePageContent } from '@/pages/b2b-ecommerce-store-app';
import CarouselB2BEcommerceCard from '@/components/CarouselB2BEcommerceCard';
import B2BEcommerceStepCard from '@/components/B2BEcommerceStepCard';
import SubscriptionComponent from '@/components/SubscriptionComponent';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const StyledFirstViewport = styled(FirstViewport)`
  @media (max-width: 1200px) {
    padding-right: 0;
  }
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  box-sizing: border-box;
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const ThirdViewport = styled(DefaultViewport)`
  padding-right: 65px;
  padding-left: 65px;
  background: linear-gradient(
    180deg,
    rgba(194, 206, 219, 0.3) 0%,
    rgba(244, 247, 249, 0.3) 7%,
    rgba(244, 247, 249, 0) 100%
  );
`;

const FourthViewPort = styled(ThirdViewport)`
  padding-top: 220px;
  padding-bottom: 200px;

  @media (max-width: 550px) {
    padding-top: 100px;
    padding-bottom: 25px;
  }
`;

const FifthViewport = styled(DefaultViewport)`
  padding: 98px 25px 173px;
  background-color: #f4f7f9;
`;

const StyledDescription = styled(P)`
  color: #495b6c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: 1.5rem 0;
  @media (max-width: 1200px) {
    padding-right: 2rem;
  }
`;

const ViewPortsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const ThirdTitle = styled(H3)`
  color: #495b6c;
  text-align: center;
  margin-bottom: 50px;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  column-gap: 25px;
  row-gap: 15px;

  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
`;

const Button = styled(Link)`
  padding: 15px 30px;
  border-radius: 5px;
  background: #13273f;
  border: none;
  text-decoration: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 470px) {
    width: calc(100% - 1rem * 2);
  }
`;

const FourthTitle = styled(P)`
  text-align: center;
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 7px;
`;

const FourthSubtitle = styled(P)`
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 43px;
  margin-bottom: 29px;
  color: #495b6c;
`;

const FifthTitle = styled(FourthTitle)`
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
`;

const FifthSubtitle = styled(FourthSubtitle)`
  margin-bottom: 59px;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  gap: 24px;
  @media (min-width: 1500px) {
    display: flex;
    justify-content: space-around;
  }
`;

interface B2BEcommerceStoreContainerProps {
  content: B2BEcommerceStorePageContent;
}

const B2BEcommerceStoreContainer: React.FunctionComponent<
  B2BEcommerceStoreContainerProps
> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5, viewport6 },
}) => (
  <>
    <FirstVpWrapper>
      <StyledFirstViewport img={viewport1Img} imagePadding="0 0 0 10rem">
        <H1>
          <span className="accent-text">{viewport1.title1}</span>
          {` `}
          {viewport1.title2}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </StyledFirstViewport>
    </FirstVpWrapper>

    <ViewPortsWrapper>
      <DefaultViewport withoutPaddingBottom>
        <ReversibleCardsComponent reversedStart cards={viewport2.cards} />
      </DefaultViewport>

      <ThirdViewport withoutPaddingBottom>
        <ThirdTitle>{viewport3.title}</ThirdTitle>
        <CardWrapper>
          {viewport3.carouselItems.map((el) => (
            <CarouselB2BEcommerceCard
              title={el.title}
              text={el.text}
              cardImgSrc={el.image}
            />
          ))}
        </CardWrapper>
        <ButtonWrapper>
          <Button
            to="https://share.hsforms.com/190Rjn8VPQPqhRObEqzSQWgby3vp"
            target="_blank"
          >
            Join the waiting list
          </Button>
        </ButtonWrapper>
      </ThirdViewport>

      <FourthViewPort>
        <FourthTitle>{viewport4.title}</FourthTitle>
        <FourthSubtitle>{viewport4.subtitle}</FourthSubtitle>
        <CardsWrapper>
          {viewport4.cards.map((el) => (
            <B2BEcommerceStepCard
              step={el.step}
              arrow={el.arrow}
              description={el.description}
              image={el.image}
            />
          ))}
        </CardsWrapper>
      </FourthViewPort>

      <FifthViewport>
        <FifthTitle>{viewport5.title}</FifthTitle>
        <FifthSubtitle>{viewport5.subtitle}</FifthSubtitle>
        <SubscriptionComponent cards={viewport5.cards} />
      </FifthViewport>

      <DefaultViewport withoutPaddingTop>
        <BigPurpleCard
          descriptionStyles={{
            color: `#F4F7F9`,
            fontSize: `18px`,
            fontWeight: 400,
            lineHeight: `24px`,
          }}
          title={viewport6.title}
          leftColumn={viewport6.leftColumn}
          rightColumn={viewport6.rightColumn}
          bottomText={viewport6.bottomText}
          button={viewport6.button}
        />
      </DefaultViewport>
    </ViewPortsWrapper>
  </>
);

export default B2BEcommerceStoreContainer;
